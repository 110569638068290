<template>
  <v-container class="pa-0 regusto-widget">
    <div class="px-2 pt-2">
      <ebsn-meta
        v-if="!hideTitle"
        class="text-uppercase"
        :target="category"
        path="category_info.TITLE"
        tag="h4"
      ></ebsn-meta>
      <ebsn-meta
        class="description"
        :target="category"
        path="category_info.DESCRIPTION"
        tag="div"
      ></ebsn-meta>
    </div>
    <div class="iframe-container">
      <iframe
        ref="regustoIframe"
        width="100%"
        :src="`https://app.regusto.eu/widget/${TOKEN}`"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </v-container>
</template>
<style lang="scss">
.regusto-widget {
  .iframe-container {
    position: relative;
    width: 100%;
    height: 1000px;
    overflow: hidden; /* Hide the scroll bars */
  }

  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "RegustoWidget",
  mixins: [categoryMixins],
  data() {
    return {
      TOKEN: "38b9d11f-19bb-41fa-b26c-e615a15be757"
    };
  },
  computed: {
    hideTitle() {
      return get(this.category, "metaData.category_info.HIDE_TITLE", false);
    }
  },
  methods: {
    setHeight() {
      //   const iframe = this.$refs.regustoIframe;
      //   if (iframe) {
      //     iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
      //   }
    }
  },
  mounted() {}
};
</script>
